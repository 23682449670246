import React from "react";
import classNames from 'classnames';

import {DownChevron} from "./Icons";

import './Dapp.css';


export class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.render = this.render.bind(this);
  }

  render() {
    const { options, selectedOption, chooseOption, classes, optionClasses } = this.props;
    if (options.length !== new Set(options).size) {
      throw new Error('Duplicate options aren\'t allowed');
    }
    let orderedOptions = options.filter((name, index) => name !== selectedOption);
    orderedOptions = [selectedOption].concat(orderedOptions);
    const openDropdown = (
      <div className={classNames('dropdown', 'open', classes)}>
        
        {orderedOptions.map((name, index) => {
          return (
            <div
              key={index}
              className={classNames('dropdown-option', optionClasses)}
              onClick={() => {
                chooseOption(name);
                this.setState({isOpen: false});
              }}
            >
              {name}
            </div>
          );
        })}
      </div>
    );
    const closedDropdown = (
      <div className={classNames('dropdown', 'closed', classes)}
        onClick={() => {this.setState({isOpen: true})}}
      >
        {selectedOption}
        <DownChevron />
      </div>
    );
    return (
      this.state.isOpen ? openDropdown : closedDropdown
    )
  }
}