import React from "react";
import {MagnifyingGlass} from "./Icons";

import './Dapp.css';


export class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      word: undefined,
    };
  }
  
  clearInput() {
    const wordInput = document.getElementById("word-input");
    wordInput.value = '';
    this.setState({word: undefined});
  }

  render() {
    const { markets, showTradeModal } = this.props;

    let resultHeaderSize;
    if (this.state.word) {
      if (this.state.word.length <= 16) {
        resultHeaderSize = 'large';
      } else if (this.state.word.length <= 22) {
        resultHeaderSize = 'medium';
      } else {
        resultHeaderSize = 'small';
      }
    }

    const canSell = markets.includes(this.state.word);
    return (
      <div className='search'>
        <form
          className='search-bar'
          onSubmit={(event) => {
            event.preventDefault();
            const formValue = event.target.elements.word.value;
            this.setState({word: formValue});
          }}
        >
          <div className='search-icon'>
            <MagnifyingGlass />
          </div>
          <input
            type="text"
            className="search-input"
            id="word-input"
            name="word"
            maxLength="31"  // not 32 because a string represented as a Solidity bytes32 needs a null terminator
            placeholder="help me"
            autoComplete="off"
          />
        </form>
        {this.state.word &&
          <div className='search-result'>
            <div className={`search-result-header ${resultHeaderSize}-text`}>
              {this.state.word}
            </div>
            <div className='search-result-buttons'>
              <button
                className='button search-result-trade-button'
                onClick={() => {
                  showTradeModal('buy', this.state.word);
                  this.clearInput();
                }}
              >
                BUY
              </button>
              <button
                className={`button search-result-trade-button ${!canSell && 'grayed-out'}`}
                onClick={() => {
                  if (canSell) {
                    showTradeModal('sell', this.state.word);
                    this.clearInput();
                  }
                }}
              >
                SELL
              </button>
            </div>
          </div>
        }
      </div>
    )
  }
}