import React from 'react';

import { X } from '../Icons';

export function NetworkErrorMessage({ message, dismiss }) {
  return (
    <div className='error-message'>
      <div
        className='xButton'
        onClick={dismiss}
      >
        <X />
      </div>
      <p>
        {message}
      </p>    
    </div>
  );
}