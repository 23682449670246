import React, { useContext } from "react";
import { useState } from "react";

import { Dropdown } from "./Dropdown";
import { AppContext } from "./Dapp";
import { X } from "./Icons";

import './Dapp.css';


export function TradeModal({ word, tradeType, tradeCallback, getPrice, onClose }) {
  // const [GWEIPrice, setPrice] = useState(undefined);
  const [GWEIPrice, setPrice] = useState(0);
  const [currency, setCurrency] = useState('ETH');
  const title = tradeType.charAt(0).toUpperCase() + tradeType.substr(1);
  const ETHDisplayPrice = parseFloat(GWEIPrice / (10**18)).toFixed(7);
  const USDDisplayPrice = ETHDisplayPrice * useContext(AppContext).price;
  const priceDisplay = (
    <div className='trade-price-and-currency'>
      <div className='trade-price'>
        {currency === 'ETH' ? ETHDisplayPrice.toString() : '$' + USDDisplayPrice.toFixed(2).toString()}
      </div>
      <Dropdown
        options={['ETH', 'USD']}
        selectedOption={currency}
        chooseOption={(name) => {setCurrency(name)}}
        classes={'currency-dropdown'}
        optionClasses={'currency-dropdown-option'}
      />
    </div>
  );


  async function updatePrice(amt) {
    const newPrice = await getPrice(amt);
    setPrice(newPrice);
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const amount = formData.get("amount");

        if (amount) {
          tradeCallback(word, amount)
          .then(event.target.reset()); // This should cause onChange to run, I think
        }
      }}
      className='trade-modal'
    >
      <div
        className='xButton'
        onClick={onClose}
      >
        <X/>
      </div>
      <div className='trade-header'>
        {title.toUpperCase()}
      </div>
      <div className='trade-word'>
        {word}
      </div>
      <div className='trade-price-getter'>
        <input
          className="trade-quantity"
          name="amount"
          placeholder="0"
          autoComplete="off"
          onInput={(event) =>
            event.target.value = event.target.value.replace(/[^0-9]/g, '')
          }
          onChange={(event) => {
            const formData = new FormData(event.target.form);
            const amount = formData.get("amount");
            updatePrice(amount);
          }}
          required
        />
        {!(GWEIPrice === undefined) && priceDisplay}
      </div>
      <input className="button trade-modal-button" type="submit" value={title.toUpperCase()} />
    </form>
  );
}