import React from 'react';

export function MagnifyingGlass() {
  return (
    <div className='magnifying-glass'>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9" stroke="#838383" strokeWidth="2"/>
        <line x1="17.4142" y1="17" x2="23" y2="22.5858" stroke="#838383" strokeWidth="2" strokeLinecap="round"/>
      </svg>
    </div>
  );
}

export function DownChevron() {
  return (
    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L6.77857 6L12 1" stroke="#403D38"/>
    </svg>
  )
}

export function X() {
  return (
    <svg width="24" height="24" viewBox="7 7 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5886 23.0053L11.2634 30.3305L7.20584 26.2729L14.531 18.9477L7.36742 11.7841L11.3891 7.76245L18.5527 14.926L25.8779 7.60086L29.9355 11.6584L22.6103 18.9836L29.8277 26.2011L25.8061 30.2228L18.5886 23.0053Z" fill="#6D685E"/>
    </svg>
  )
}

