import React from "react";

import { ethers } from "ethers";
import './Dapp.css';

import { NoWalletDetected } from "./network/NoWalletDetected";
import { ConnectWallet } from "./network/ConnectWallet";
import { TransactionErrorMessage } from "./network/TransactionErrorMessage";
import { WaitingForTransactionMessage } from "./network/WaitingForTransactionMessage";
import {Market} from "./Market";
import {Search} from "./Search";
import { TradeModal } from "./TradeModal";

let ContractArtifact;
if (process.env.REACT_APP_ENV === 'prod') {
  import("../contracts/WordEconomy.json")  // for the contract deployed to prod
    .then(module => {
        ContractArtifact = module.default;
    });
} else {
    // I don't think the try/catch should be necessary with dynamic imports, but
    // this isn't working otherwise. Maybe some issue with the CRA compilation,
    // wonder if setting "type": "module" in the package.json would fix it
    try {
      import("../contracts/output/WordEconomy.sol/WordEconomy.json")
      .then(module => {
        ContractArtifact = module.default;
      });
    } catch (error) {
      console.log(error);
  }
}

// This is the Hardhat Network id that we set in our hardhat.config.js.
// Here's a list of network ids https://docs.metamask.io/guide/ethereum-provider.html#properties
// to use when deploying to other networks.

const SEPOLIA_CONTRACT_ADDRESS = '0x8144F8Ce517D1E6f70A076c0F1Eb95867BdCA1d0';
const POLYGON_MAINNET_CONTRACT_ADDRESS = '0x8144f8ce517d1e6f70a076c0f1eb95867bdca1d0';

const network = process.env.REACT_APP_NETWORK;
let contractAddress;
if (network === 'sepolia') {
  contractAddress = SEPOLIA_CONTRACT_ADDRESS;
} else if (network === 'polygon_mainnet') {
  contractAddress = POLYGON_MAINNET_CONTRACT_ADDRESS;
} else {
  fetch("/run-latest.json")
  .then(response => response.json())
  .then(json => {contractAddress = json.transactions[0].contractAddress});
}

const NETWORK_IDS = {
  hardhat: '31337',
  anvil: '31337',
  sepolia: '11155111',
  goerli: '5',
  polygon_mainnet: '137'
}

const NETWORK_NAMES = {
  'hardhat': 'Hardhat',
  'anvil': 'Anvil',
  'sepolia': 'Sepolia Testnet',
  'goerli': 'Goerli Testnet',
  'polygon_mainnet': 'Polygon Mainnet'
}

// This is an error code that indicates that the user canceled a transaction
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

export const AppContext = React.createContext();

// The Dapp component:
//   1. Connects to the user's wallet
//   2. Initializes ethers and the WordEconomy contract
//   3. Polls the user's balance to keep it updated
//   4. Lets the user view their holdings and buy/sell words
//   5. Renders the whole application


export class Dapp extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      selectedAddress: undefined,
      markets: [],
      txBeingSent: undefined,
      messageDuringTx: undefined,
      transactionError: undefined,
      networkError: undefined,
      ETHToUSD: undefined,
      tradeVisible: null,
      tradeModalWord: undefined
    };

    this.state = this.initialState;

    this.getBuyPrice = this.getBuyPrice.bind(this);
    this.getSellPrice = this.getBuyPrice.bind(this);
    this.buyToken = this.buyToken.bind(this);
    this.sellToken = this.sellToken.bind(this);
    this.getTokenBalance = this.getTokenBalance.bind(this);
    this.showTradeModal = this.showTradeModal.bind(this);
    this.hideTradeModal = this.hideTradeModal.bind(this);
  }

  render() {
    // Ethereum wallets inject the window.ethereum object. If it hasn't been
    // injected, we instruct the user to install MetaMask.
    if (window.ethereum === undefined) {
      return <NoWalletDetected />;
    }

    // The next thing we need to do is ask the user to connect their wallet.
    // When the wallet gets connected, we are going to save the user's address
    // in the component's state. So if it hasn't been saved yet, we have
    // to show the ConnectWallet component.
    //
    // Note that we pass it a callback that gets called when the user
    // clicks a button. This callback just calls the _connectWallet method.
    if (!this.state.selectedAddress) {
      return (
        <ConnectWallet 
          connectWallet={() => this._connectWallet()} 
          networkError={this.state.networkError}
          dismiss={() => this._dismissNetworkError()}
        />
      );
    }

    let markets = (
      <div className='empty-portfolio-message'>
        <br/>
        you are without words.
      </div>
    );
    if (this.state.markets.length) {
      markets = this.state.markets.map(word => 
        <Market
          key={word}
          word={word}
          getBalance={this.getTokenBalance}
          buyToken={this.buyToken}
          sellToken={this.sellToken}
          getBuyPrice={this.getBuyPrice}
          getSellPrice={this.getSellPrice}
          showTradeModal={this.showTradeModal}
        />
      );
    }

    // If the token data or the user's balance hasn't loaded yet, we show
    // a loading component.
    // if (!this.state.tokenData || !this.state.balance) {
    //   return <Loading />;
    // }

    // If everything is loaded, we render the application.
    return (
      <AppContext.Provider
        value={{
          price: this.state.ETHToUSD
        }}
      >
        <div className="everything">
          <h1 className='header'>
            STRING DEPOT
          </h1>

          <div className='middle'>
            <Search
              markets={this.state.markets}
              showTradeModal={this.showTradeModal}
            />
            <div className='portfolio-box'>
              <div className='portfolio-header'>
                PORTFOLIO
              </div>
              {markets}
            </div>
          </div>

          {this.state.tradeVisible === 'buy' && <TradeModal
            word={this.state.tradeModalWord}
            tradeType='buy'
            tradeCallback={(word, amount) =>
              this.buyToken(word, amount).then(this.hideTradeModal())
            }
            getPrice={(amount) => {
              // TODO check that empty string != null
              if (this.state.tradeModalWord != null) {
                return this.getBuyPrice(this.state.tradeModalWord, amount);
              } else {
                return null;
              }
            }}
            onClose={this.hideTradeModal}
          />}
          {this.state.tradeVisible === 'sell' && <TradeModal
            word={this.state.tradeModalWord}
            tradeType='sell'
            tradeCallback={(word, amount) =>
              this.sellToken(word, amount).then(this.hideTradeModal())
            }
            getPrice={(amount) => {
              // TODO check that empty string != null
              if (this.state.tradeModalWord != null) {
                return this.getSellPrice(this.state.tradeModalWord, amount);
              } else {
                return null;
              }
            }}
            onClose={this.hideTradeModal}
          />}

          <div className="row">
            <div className="col-12">
              {/* 
                Sending a transaction isn't an immediate action. You have to wait
                for it to be mined.
                If we are waiting for one, we show a message here.
              */}
              {this.state.txBeingSent && (
                <WaitingForTransactionMessage
                  txHash={this.state.txBeingSent}
                  message={this.state.messageDuringTx}
                />
              )}

              {/* 
                Sending a transaction can fail in multiple ways. 
                If that happened, we show a message here.
              */}
              {this.state.transactionError && (
                <TransactionErrorMessage
                  message={this._getRpcErrorMessage(this.state.transactionError)}
                  dismiss={() => this._dismissTransactionError()}
                />
              )}
            </div>
          </div>
        </div>
      </AppContext.Provider>
    );
  }


  async _connectWallet() {
    // This method is run when the user clicks the Connect. It connects the
    // dapp to the user's wallet, and initializes it.

    // To connect to the user's wallet, we have to run this method.
    // It returns a promise that will resolve to the user's address.
    const [selectedAddress] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    // Once we have the address, we can initialize the application.

    // First we check the network
    if (!this._checkNetwork()) {
      return;
    }

    this._initialize(selectedAddress);

    // We reinitialize it whenever the user changes their account.
    window.ethereum.on("accountsChanged", ([newAddress]) => {
      // `accountsChanged` event can be triggered with an undefined newAddress.
      // This happens when the user removes the Dapp from the "Connected
      // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
      // To avoid errors, we reset the dapp state 
      if (newAddress === undefined) {
        return this._resetState();
      }
      
      this._initialize(newAddress);
    });
    
    // We reset the dapp state if the network is changed
    window.ethereum.on("chainChanged", ([chainId]) => {
      this._resetState();
    });
  }

  async _initialize(userAddress) {
    await this._initializeEthers();
    this.setState({
      selectedAddress: userAddress,
    }, this._updateHoldings);
    
    const CHAINLINK_URL = 'https://min-api.cryptocompare.com/data/pricemultifull?fsyms=ETH&tsyms=USD';
    fetch(CHAINLINK_URL)
      .then(response => response.json())
      .then(data => {
        const ETHPrice = data['RAW']['ETH']['USD']['PRICE'];
        this.setState({ETHToUSD: ETHPrice});
      })
      .catch(error => {
        console.error(error);
      })
  }

  async _initializeEthers() {
    this._provider = new ethers.providers.Web3Provider(window.ethereum);
    this._economy = new ethers.Contract(
      contractAddress,
      ContractArtifact.abi,
      this._provider.getSigner(0)
    );
    await this._economy.deployed();
  }

  async getTokenBalance(word) {
    const bytes = ethers.utils.formatBytes32String(word);
    const balance = await this._economy.getWordBalance(bytes, this.state.selectedAddress);
    return balance;
  }

  async _updateHoldings() {
    const code = await this._provider.getCode(this._economy.address);
    let holdings = await this._economy.getHoldings(this.state.selectedAddress);
    holdings = holdings.map(w => ethers.utils.parseBytes32String(w));
    this.setState({markets: holdings});
  }

  async _monitorTransaction(tx, message=undefined) {
    // Sending a transaction is a complex operation:
    //   - The user can reject it
    //   - It can fail before reaching the ethereum network (i.e. if the user
    //     doesn't have ETH for paying for the tx's gas)
    //   - It has to be mined, so it isn't immediately confirmed.
    //     Note that some testing networks, like Hardhat Network, do mine
    //     transactions immediately, but your dapp should be prepared for
    //     other networks.
    //   - It can fail once mined.
    //
    // This method handles all of those things, so keep reading to learn how to
    // do it.

    try {
      // If a transaction fails, we save that error in the component's state.
      // We only save one such error, so before sending a second transaction, we
      // clear it.
      this._dismissTransactionError();

      // We send the transaction, and save its hash in the Dapp's state. This
      // way we can indicate that we are waiting for it to be mined.
      this.setState({ txBeingSent: tx.hash, messageDuringTx: message });

      // We use .wait() to wait for the transaction to be mined. This method
      // returns the transaction's receipt.
      const receipt = await tx.wait();

      // The receipt contains a status flag, which is 0 to indicate an error.
      if (receipt.status === 0) {
        // We can't know the exact error that made the transaction fail when it
        // was mined, so we throw this generic one.
        throw new Error("Transaction failed");
      }

      // If we got here, the transaction was successful, so you may want to
      // update your state. Here, we update the user's balance.
      await this._updateHoldings();
    } catch (error) {
      // We check the error code to see if this error was produced because the
      // user rejected a tx. If that's the case, we do nothing.
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }

      // Other errors are logged and stored in the Dapp's state. This is used to
      // show them to the user, and for debugging.
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      // If we leave the try/catch, we aren't sending a tx anymore, so we clear
      // this part of the state.
      this.setState({ txBeingSent: undefined, messageDuringTx: undefined });
    }
  }

  async buyToken(word, amount) {
    const bytes = ethers.utils.formatBytes32String(word);
    const price = await this._economy.getBuyPrice(bytes, amount);
    const tx = await this._economy.buyWord(bytes, amount, {value: price});
    this._monitorTransaction(tx, 'buying...');
    this._updateHoldings();
  }

  async getBuyPrice(word, amount) {
    if (!(Number(amount))) {
      return null;
    }
    const bytes = ethers.utils.formatBytes32String(word);
    const price = await this._economy.getBuyPrice(bytes, amount);
    return price;
  }

  async getSellPrice(word, amount) {
    if (!amount) {
      return null;
    }
    const bytes = ethers.utils.formatBytes32String(word);
    const token = await this._economy.getWord(bytes);
    if (token === ethers.constants.AddressZero) {
      throw new Error("A market for this string doesn't exist yet");
    }
    const price = await this._economy.getSellPrice(amount);
    return price;
  }

  async _transferTokens(word, to, amount) {
    const tx = await this._token.transfer(to, amount);
    this._monitorTransaction(tx, 'transferring...');
  }

  async sellToken(word, amount) {
    const bytes = ethers.utils.formatBytes32String(word);

    const token = await this._economy.getWord(bytes);
    if (token === ethers.constants.AddressZero) {
      throw new Error("A market for this string doesn't exist yet");
    }

    const tx = await this._economy.sellWord(bytes, amount);
    this._monitorTransaction(tx, 'selling...');
    this._updateHoldings();
  }

  showTradeModal(tradeType, word) {
    this.setState({
      tradeVisible: tradeType,
      tradeModalWord: word
    });
  }

  hideTradeModal() {
    this.setState({
      tradeVisible: null,
      tradeModalWord: undefined
    });
  }

  // This method just clears part of the state.
  _dismissTransactionError() {
    this.setState({ transactionError: undefined });
  }

  // This method just clears part of the state.
  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  // This is an utility method that turns an RPC error into a human readable
  // message.
  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }

    return error.message;
  }

  // This method resets the state
  _resetState() {
    this.setState(this.initialState);
  }

  // This method checks if Metamask selected network is Localhost:8545 
  _checkNetwork() {
    if (parseInt(window.ethereum.chainId, 16).toString() === NETWORK_IDS[network]) {
      return true;
    }

    this.setState({ 
      networkError: `Connect to ${NETWORK_NAMES[network]}`
    });

    return false;
  }
}