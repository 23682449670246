import React from "react";

import { X } from '../Icons';

export function TransactionErrorMessage({ message, dismiss }) {
  return (
    <div className='message-modal'>
      <div
        className='xButton'
        onClick={dismiss}
      >
        <X />
      </div>
      <p>
        <b>Error sending transaction</b> <br />
        {message.substring(0, 100)}
      </p>
    </div>
  );
}
