import React from "react";

import './Dapp.css';


export class Market extends React.Component {
  constructor(props) {
    super(props);  // word, getBalance, buyToken, getBuyPrice
    this.state = {
      balance: undefined
    };
  }

  async _updateBalance() {
    const balance = await this.props.getBalance(this.props.word);
    this.setState({ balance });
  }
  
  _startPollingData() {
    this._pollDataInterval = setInterval(() => this._updateBalance(), 1000);

    // We run it once immediately so we don't have to wait for it
    this._updateBalance();
  }

  _stopPollingData() {
    clearInterval(this._pollDataInterval);
    this._pollDataInterval = undefined;
  }

  componentDidMount() {
    this._startPollingData();
  }

  componentWillUnmount() {
    // We poll the user's balance, so we have to stop doing that when Dapp
    // gets unmounted
    this._stopPollingData();
  }

  render() {
    const { word, buyToken, getBuyPrice, sellToken, getSellPrice, showTradeModal } = this.props;
    const balanceDisplay = this.state.balance == 1 ? '1 share' : `${this.state.balance} shares`
    return (
      <div className='portfolio-row'>
        <div className='portfolio-row-word'>{word}</div>
        <div className='portfolio-row-lower-half'>
          <div className='portfolio-row-balance'>{balanceDisplay}</div>
          <div className='buy-and-sell-buttons'>
            <button
              className='button portfolio-trade-button'
              onClick={() => showTradeModal('buy', word)}
            >
              BUY
            </button>
            <button
              className='button portfolio-trade-button'
              onClick={() => showTradeModal('sell', word)}
            >
              SELL
            </button>
            {/* <Trade
              tradeType="buy"
              tradeCallback={(amount) =>
                buyToken(word, amount)
              }
              getPrice={(amount) =>
                getBuyPrice(word, amount)
              }
            />
            <Trade
              tradeType="sell"
              tradeCallback={(amount) =>
                sellToken(word, amount)
              }
              getPrice={(amount) =>
                getSellPrice(word, amount)
              }
            /> */}
          </div>
        </div>
      </div>
    );
  }
}