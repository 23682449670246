import React from "react";

export function NoWalletDetected() {
  return (
    <div className='everything'>
      <p className='no-wallet-detected'>
        no Ethereum wallet found. <br />
        you might need{' '}
        <a
          href="http://metamask.io"
          target="_blank"
        >
          MetaMask
        </a>{' '}
        or something.
      </p>
    </div>
  );
}
